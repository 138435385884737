@import url(https://fonts.googleapis.com/css2?family=Heebo:wght@300;400;500;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Heebo:wght@300;400;500;700;800;900&display=swap);
*{ margin: 0px; padding: 0px; box-sizing: border-box; }
html, body, #root{ height: 100% }


.auth-cntr{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.auth-form-cntr{
  margin: 4rem 1rem;
  max-width: 534px;
  padding: 60px 40px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,.2);
  background-color: white;
}


.errorMsg{
  color: #dd2c00;
  font-size: 13px;
  min-height: 30px;
}


#header-bar{
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background-color: rgba(255,255,255,1);
  box-shadow: 0 2px 4px rgba(0,0,0,.08), 0 4px 12px rgba(0,0,0,.08);
  border-bottom: 1px solid #8f7575; */
  /* margin-bottom: 5rem; */
}

.site-logo-cntr{
  display: flex;
  align-items: flex-end;
  /* margin: 19px 0px; */
}

.site-logo-cntr img{
 max-height: 100%;
}

.site-title{
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  margin-left: 8px;
}

.merriwether{
  font-family: 'Merriweather', serif;
}

.red{
  color:#FF3D00;
}

#header-bar a{
  color: #2d2d2d;
  cursor: pointer;
}
.policies-cntr a{
  margin-right: 1rem;
  text-decoration: none;
  line-height: 2;
}

.policies-cntr{ display: flex;}
.copyright{line-height: 2;}

@media (max-width: 575.98px) { }

@media (max-width: 767.98px) { 
  .footer-cntr{flex-direction: column-reverse;}
  .copyright{margin-top: 16px; font-size: 14px;}
  .policies-cntr{display: block;}
 }

@media (max-width: 991.98px) {  }

@media (max-width: 1199.98px) {  }
.public-hero.mobile{
  height: 50.51vw; position: relative;
  background-size: cover;
  background-repeat:   no-repeat;
  background-position: center center;
  /* display: flex; */
}

.public-hero.desktop{
  padding: 100px 90px 0px;
  height: calc(100vh - 64px); position: relative;
  background-size: cover;
  background-repeat:   no-repeat;
  background-position: center bottom;
}

.text-box-desktop{
  width: 450px;
  padding: 40px;
  background-color: rgba(255,255,255,.8);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  box-shadow: 0 20px 20px rgba(0,0,0,.08);
  max-width: 630px; color: #677b8c;
  font-size: 18px;
  line-height: 28px;
}

.text-box{
  margin: -30px 20px 20px;
  padding: 30px 30px;
  background-color: rgba(255,255,255,.8);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  box-shadow: 0 20px 20px rgba(0,0,0,.08);
  max-width: 630px; color: #677b8c;
  font-size: 18px;
  line-height: 28px;
}

.header-bar{
  width: 100%;
  display: flex;
  height: 62px;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
}

.header-bar a{
  margin-right: 30px;
  text-decoration: none;
}
.button{font-size: 14px; font-weight: 500; text-transform: uppercase; text-decoration: none}
.button.active{ background-color: #FFBC36; border-radius: 50px; border: 3px solid #FFBC36 }
.button.active.text{background-color: transparent !important; border: 3px solid transparent; color: #333}
.button.active.outlined{background-color: transparent !important; border: 3px solid #333; color: #333}

.button.active:hover{ background-color: #FFBC36 !important; opacity: 0.8; }
.button.active.text:hover{background-color: transparent !important; opacity: 0.8;}
.p_card{
  display: inline-block;
  position: relative;
  max-width: 355px;
  background-color: white;
  vertical-align: top;
  text-align: left;
  height: 490px;
  margin: 20px;
  box-shadow: 0 20px 20px rgba(0,0,0,.08);
  white-space: normal;
  transition: box-shadow 200ms cubic-bezier(.02, .01, .47, 1), transform 200ms cubic-bezier(.02, .01, .47, 1);
  color: #4B4F56;
}

.p_card:hover {
  box-shadow: 0 40px 40px rgba(0,0,0,.16);
  transform: translate(0,-20px);
  transition-delay: 0s !important;
}

.p_card-body {
  position: relative;
  clear: both;
  float: left;
  width: 100%;
  overflow: visible;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 16px;
  z-index: 2;
}

.p_card-title{
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 12px;
  color: #344854;
}

.p_card-subtitle{
  font-size: 12px;
  /* font-weight: 500; */
  line-height: 30px;
  /* margin-bottom: 6px; */
  color: #677B8C;
}


.p_card-description{
  display: block;
  display: -webkit-box;
  font-size: 15px;
  font-weight: 400;
  text-align: left;
  line-height: 22px;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #677B8C;
}

.p_card-hero{
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  position: relative;
  clear: both;
  float: left;
  overflow: auto;
  width: 100%;
  padding: 20px;
  height: 150px;
}

.media-type{
  /* position: absolute;
  right: 15px;
  top: 15px; */
  height: 36px;
  width: 36px;
  border-radius: 30px;
  border: 4px solid rgba(255,255,255,.8);
  background-color: #FFBC36;
  display: flex; justify-content: center; align-items: center;
}

.p_card-footer{
  font-size: 11px;
  font-weight: 500;
  letter-spacing: .68px;
  text-transform: uppercase;
  position: absolute;
  padding-left: 20px;
  padding-right: 20px;
  bottom: 0px;
  margin: 0 auto;
  width: 100%;
}

.card-footer-wrapper {
  height: 46px;
  border-top: 1px solid #E9EBEE;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
a{display: block;}
.section-header-blk{margin: 25px}

@media (max-width: 575.98px) { }

@media (max-width: 767.98px) { 
  .dashboard-banner{
    flex-direction: column;
  }
  .section-header-blk{margin: 0px}
 }

@media (max-width: 991.98px) {  }

@media (max-width: 1199.98px) {  }
.glide{position:relative;width:100%;box-sizing:border-box}.glide *{box-sizing:inherit}.glide__track{overflow:hidden}.glide__slides{position:relative;width:100%;list-style:none;-webkit-backface-visibility:hidden;backface-visibility:hidden;transform-style:preserve-3d;touch-action:pan-Y;overflow:hidden;padding:0;white-space:nowrap;display:flex;flex-wrap:nowrap;will-change:transform}.glide__slides--dragging{-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.glide__slide{width:100%;height:100%;flex-shrink:0;white-space:normal;white-space:initial;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;-webkit-touch-callout:none;-webkit-tap-highlight-color:transparent}.glide__slide a{-webkit-user-select:none;user-select:none;-webkit-user-drag:none;-moz-user-select:none;-ms-user-select:none}.glide__arrows{-webkit-touch-callout:none;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.glide__bullets{-webkit-touch-callout:none;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.glide--rtl{direction:rtl}
.glide__arrow{position:absolute;display:block;top:50%;z-index:2;color:white;text-transform:uppercase;padding:9px 12px;background-color:transparent;border:2px solid rgba(255,255,255,0.5);border-radius:4px;box-shadow:0 0.25em 0.5em 0 rgba(0,0,0,0.1);text-shadow:0 0.25em 0.5em rgba(0,0,0,0.1);opacity:1;cursor:pointer;transition:opacity 150ms ease, border 300ms ease-in-out;transform:translateY(-50%);line-height:1}.glide__arrow:focus{outline:none}.glide__arrow:hover{border-color:white}.glide__arrow--left{left:2em}.glide__arrow--right{right:2em}.glide__arrow--disabled{opacity:0.33}.glide__bullets{position:absolute;z-index:2;bottom:2em;left:50%;display:inline-flex;list-style:none;transform:translateX(-50%)}.glide__bullet{background-color:rgba(255,255,255,0.5);width:9px;height:9px;padding:0;border-radius:50%;border:2px solid transparent;transition:all 300ms ease-in-out;cursor:pointer;line-height:0;box-shadow:0 0.25em 0.5em 0 rgba(0,0,0,0.1);margin:0 0.25em}.glide__bullet:focus{outline:none}.glide__bullet:hover,.glide__bullet:focus{border:2px solid white;background-color:rgba(255,255,255,0.5)}.glide__bullet--active{background-color:white}.glide--swipeable{cursor:grab;cursor:-webkit-grab}.glide--dragging{cursor:grabbing;cursor:-webkit-grabbing}
#_book_root{
  height: 100%;
  overflow: hidden;
}

.loading-screen{
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #333333;
  color: #FFFFFF;
}

._book-cntr{
  display: flex;
  flex-direction: column;
  justify-items: flex-end;
  flex-grow: 1;
}

._book_meta_cntr{
  height: 100%;
  background-color: #333333;
}

.page-cntr{
  background-color: #333333;
  margin: 0px auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-numbers{
  position: absolute;
  height: 50px;
  width: 100px;
  top: 0px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-family: Georgia, 'Times New Roman', Times, serif;
  color: #c6c6c6;
}

.page-numbers span{color: #c6c6c6}

#current-page{
  font-size: 28px;
  line-height: 23px;
}

#number-of-pages{
  font-size: 16px;
  line-height: 16px;
}

.glide__arrow{
  border: none;
  padding: 9px 2px;
  border-radius: 0px;
  background-color: #cdcdcd;
}

.glide__track{overflow: visible;overflow: initial;}

.glide__arrow--left{ border-radius: 0px 15px 15px 0px; left: 0px }
.glide__arrow--right{ border-radius: 15px 0px 0px 15px; right: 0px }
*{font-family: 'Heebo', sans-serif; color: #2d2d2d;}

.ch-sub-title {
  font-size: 1.125rem;
  color: #686868;
}
*{font-family: 'Heebo', sans-serif;}
#root{
  background-color: #fcfcfc; height: 100%; width: 100%; overflow: hidden;
}
