#_book_root{
  height: 100%;
  overflow: hidden;
}

.loading-screen{
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #333333;
  color: #FFFFFF;
}

._book-cntr{
  display: flex;
  flex-direction: column;
  justify-items: flex-end;
  flex-grow: 1;
}

._book_meta_cntr{
  height: 100%;
  background-color: #333333;
}

.page-cntr{
  background-color: #333333;
  margin: 0px auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-numbers{
  position: absolute;
  height: 50px;
  width: 100px;
  top: 0px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-family: Georgia, 'Times New Roman', Times, serif;
  color: #c6c6c6;
}

.page-numbers span{color: #c6c6c6}

#current-page{
  font-size: 28px;
  line-height: 23px;
}

#number-of-pages{
  font-size: 16px;
  line-height: 16px;
}
