.p_card{
  display: inline-block;
  position: relative;
  max-width: 355px;
  background-color: white;
  vertical-align: top;
  text-align: left;
  height: 490px;
  margin: 20px;
  box-shadow: 0 20px 20px rgba(0,0,0,.08);
  white-space: normal;
  -webkit-transition: box-shadow 200ms cubic-bezier(.02, .01, .47, 1), transform 200ms cubic-bezier(.02, .01, .47, 1);
  -moz-transition: box-shadow 200ms cubic-bezier(.02, .01, .47, 1), transform 200ms cubic-bezier(.02, .01, .47, 1);
  transition: box-shadow 200ms cubic-bezier(.02, .01, .47, 1), transform 200ms cubic-bezier(.02, .01, .47, 1);
  color: #4B4F56;
}

.p_card:hover {
  box-shadow: 0 40px 40px rgba(0,0,0,.16);
  transform: translate(0,-20px);
  transition-delay: 0s !important;
}

.p_card-body {
  position: relative;
  clear: both;
  float: left;
  width: 100%;
  overflow: visible;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 16px;
  z-index: 2;
}

.p_card-title{
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 12px;
  color: #344854;
}

.p_card-subtitle{
  font-size: 12px;
  /* font-weight: 500; */
  line-height: 30px;
  /* margin-bottom: 6px; */
  color: #677B8C;
}


.p_card-description{
  display: block;
  display: -webkit-box;
  font-size: 15px;
  font-weight: 400;
  text-align: left;
  line-height: 22px;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #677B8C;
}

.p_card-hero{
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  position: relative;
  clear: both;
  float: left;
  overflow: auto;
  width: 100%;
  padding: 20px;
  height: 150px;
}

.media-type{
  /* position: absolute;
  right: 15px;
  top: 15px; */
  height: 36px;
  width: 36px;
  border-radius: 30px;
  border: 4px solid rgba(255,255,255,.8);
  background-color: #FFBC36;
  display: flex; justify-content: center; align-items: center;
}

.p_card-footer{
  font-size: 11px;
  font-weight: 500;
  letter-spacing: .68px;
  text-transform: uppercase;
  position: absolute;
  padding-left: 20px;
  padding-right: 20px;
  bottom: 0px;
  margin: 0 auto;
  width: 100%;
}

.card-footer-wrapper {
  height: 46px;
  border-top: 1px solid #E9EBEE;
  display: flex;
  justify-content: space-between;
  align-items: center;
}