a{display: block;}
.section-header-blk{margin: 25px}

@media (max-width: 575.98px) { }

@media (max-width: 767.98px) { 
  .dashboard-banner{
    flex-direction: column;
  }
  .section-header-blk{margin: 0px}
 }

@media (max-width: 991.98px) {  }

@media (max-width: 1199.98px) {  }