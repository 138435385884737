#header-bar{
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background-color: rgba(255,255,255,1);
  box-shadow: 0 2px 4px rgba(0,0,0,.08), 0 4px 12px rgba(0,0,0,.08);
  border-bottom: 1px solid #8f7575; */
  /* margin-bottom: 5rem; */
}

.site-logo-cntr{
  display: flex;
  align-items: flex-end;
  /* margin: 19px 0px; */
}

.site-logo-cntr img{
 max-height: 100%;
}

.site-title{
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  margin-left: 8px;
}

.merriwether{
  font-family: 'Merriweather', serif;
}

.red{
  color:#FF3D00;
}

#header-bar a{
  color: #2d2d2d;
  cursor: pointer;
}