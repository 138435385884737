.auth-cntr{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.auth-form-cntr{
  margin: 4rem 1rem;
  max-width: 534px;
  padding: 60px 40px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,.2);
  background-color: white;
}


.errorMsg{
  color: #dd2c00;
  font-size: 13px;
  min-height: 30px;
}

