.glide__arrow{
  border: none;
  padding: 9px 2px;
  border-radius: 0px;
  background-color: #cdcdcd;
}

.glide__track{overflow: initial;}

.glide__arrow--left{ border-radius: 0px 15px 15px 0px; left: 0px }
.glide__arrow--right{ border-radius: 15px 0px 0px 15px; right: 0px }